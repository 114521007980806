.intercom-namespace .intercom-1ybzmum {
  z-index: unset !important;
}

* {
  font-family: Montserrat !important;
}

.App {
  text-align: center;
}

.App-link {
  color: #61dafb;
}

#root {
  min-height: 100vh;
  height: 100%;
  min-width: 350px;
}

/* Generics */
.header-container {
  display: flex;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: #d6d6d6;
  /* min-height: 100px; */
}

.page-header-title {
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.98px;
  color: #000000;
}

.nopadding {
  padding: 0 !important;
}

.nomargin {
  margin: 0 !important;
}

.view-all-button {
  color: #208bfe;
  font-family: Montserrat-Medium !important;
}

.view-all-button:hover {
  cursor: pointer;
}

.view-all-button-disabled {
  color: #a8a8a8;
}

.view-all-button-disabled:hover {
  cursor: initial;
}

.truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 340px;
}

.no-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
}

.modal-content {
  min-height: 417px;
}

.candidate-detail-modal .modal-content,
#company-detail-modal .modal-content,
.project-detail-modal .modal-content {
  overflow: auto;
}

.standard-theme-text {
  font-family: Montserrat;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
}
/* Placeholder Font Style */
::-webkit-input-placeholder,
::-moz-placeholder,
:-ms-input-placeholder,
:-moz-placeholder {
  font-family: Montserrat;
  font-size: 14px;
}

span.contact-icon-span-text > a {
  color: #208bfe !important;
}

span.contact-icon-span-text > a:hover {
  text-decoration: none !important;
  color: #208bfe !important;
}

/* Navbar Section */
.navbar-container {
  background-color: #208bfe;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}

.navbar-light .navbar-toggler {
  border: none;
}

.nav-bar-link-active:hover,
.nav-bar-link:hover {
  font-family: Montserrat;
  cursor: pointer;
  color: #fff !important;
  opacity: 1;
  text-shadow: 0.65px 0px 0px white;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.57px;
  text-align: right;
  color: #ffffff;
  text-decoration: none;
}

.nav-bar-link {
  font-family: Montserrat;
  color: #fff!;
  opacity: 1;
  opacity: 0.6;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.57px;
  text-align: right;
  color: #ffffff;
}

.nav-bar-link-active {
  font-family: Montserrat-Medium !important;
  opacity: 1;
  text-shadow: 0.65px 0px 0px white;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.57px;
  text-align: right;
  color: #ffffff;
}

/* Project */
.project-container {
  border: 1px solid #d6d6d6;
  display: flex;
  flex-flow: column;
  text-align: center;
  min-height: 350px;
  flex: 1 1;
}

#project-grid-container.project-container,
#company-grid-container.project-container {
  min-height: 397px;
}

.project-body {
  display: flex;
  flex-flow: column;
  align-items: center;
  margin: 15px;
}
.project-container:hover .project-name {
  color: #208bfe;
  cursor: pointer;
}

.candidates-text {
  color: #a8a8a8;
}

/* Candidate */
.candidate-body {
  border-left-width: 1px;
  border-left-style: solid;
  border-left-color: #d6d6d6;
}

.candidate-card {
  height: 394px;
  flex: 1 1;
  border: 1px solid #d6d6d6;
  display: flex;
  flex-flow: column;
  text-align: center;
  cursor: pointer;
  justify-content: center;
  padding-top: 37.4px;
  padding-bottom: 25px;
  min-width: 394px;
}

/* Candidate Row UI */
.candidate-row {
  display: flex;
  flex-flow: row;
  width: 100%;
  position: relative;
  padding: 38px 20px 15px 56px;
}

.candidate-row .candidate-info-container .truncate {
  width: 100%;
}

.row-first-container {
  cursor: pointer;
}

.row-first-container:hover .name {
  color: #208bfe;
  cursor: pointer;
}

.candidate-row .candidate-detail-section {
  width: 100%;
}
.candidate-row .candidate-detail-section-header-container {
  margin-bottom: 10px;
}

.candidate-row .experience-skills-container .mobile-experience-container {
  width: 100%;
  padding: initial;
  margin: initial;
  padding-left: 11px;
  padding-right: 10px;
  margin-top: 10px;
}

.candidate-row .experience-skills-container .skills-row-container {
  padding-left: 4px;
  padding-top: 5px;
  overflow: hidden;
}

.candidate-row .candidate-row-social-container .unlock-container,
.candidate-row .candidate-row-contact-container .unlock-container,
.candidate-row .mobile-candidate-row-social-container .unlock-container,
.candidate-row .mobile-candidate-row-contact-container .unlock-container {
  display: none;
}

.candidate-row .candidate-row-social-container .contact-header-container,
.candidate-row .candidate-row-contact-container .contact-header-container {
  margin: initial;
  min-height: initial;
}

.candidate-row .contact-bubble-unlock-container {
  display: none;
}

.candidate-row .candidate-row-social-container .social-contact-header-text,
.candidate-row .candidate-row-contact-container .social-contact-header-text {
  font-size: 11px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.45px;
  color: #9b9b9b;
  text-transform: uppercase;
  margin-bottom: 5px;
}

.candidate-row .mobile-candidate-row-social-container .social-contact-header-text,
.candidate-row .mobile-candidate-row-contact-container .social-contact-header-text {
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: -0.71px;
  color: #373737;
  margin-bottom: 5px;
}

.candidate-detail-modal-body .social-contact-header-text {
  font-family: Montserrat-Bold !important;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: -0.71px;
  color: #373737;
}

.candidate-detail-modal-body .last-viewed-text {
  font-size: 12px;
}

.candidate-row .candidate-row-social-container .social-icon-row,
.candidate-row .candidate-row-contact-container .contact-icon-row {
  padding-left: 0px;
}

.candidate-row .candidate-row-social-container .social-icon-row-wrapper,
.candidate-row .candidate-row-contact-container .contact-icon-row-wrapper,
.candidate-row .mobile-candidate-row-social-container .social-icon-row-wrapper,
.candidate-row .mobile-candidate-row-contact-container .contact-icon-row-wrapper {
  justify-content: initial;
}

.candidate-row .mobile-candidate-row-social-container .social-icon-row-wrapper,
.candidate-row .mobile-candidate-row-contact-container .contact-icon-row-wrapper {
  padding-left: 10px;
  min-height: initial;
}

.candidate-row .candidate-row-social-container .social-icon-row-wrapper {
  justify-content: initial;
}

.candidate-row .candidate-row-social-container .icon-background-container {
  width: 18px;
  height: 18px;
  margin-right: 5px;
}

.candidate-row .candidate-row-social-container .icon-background-container > img,
.candidate-row .candidate-row-contact-container .icon-background-container > img {
  width: 10px;
  height: 12px;
}

.candidate-row .unlock-button {
  display: none;
}

.candidate-row .candidate-row-contact-container .icon-background-container {
  width: 18px;
  height: 18px;
  margin-right: 11px;
}

.candidate-row .mobile-candidate-row-contact-container .icon-background-container,
.candidate-row .mobile-candidate-row-social-container .icon-background-container {
  width: 29px;
  height: 29px;
}

.candidate-row .mobile-candidate-row-social-container .icon-background-container > img,
.candidate-row .mobile-candidate-row-contact-container .icon-background-container > img {
  width: 15px;
  height: 15px;
}

.candidate-row .mobile-candidate-row-contact-container .icon-background-container {
  margin-right: 16px;
}

.candidate-row .mobile-candidate-row-social-container .icon-background-container {
  margin-right: 7px;
}

/* Add Button */
.add-button:hover {
  background-color: #208bfe;
  color: #ffffff !important;
  cursor: pointer;
}
.add-button:hover .plus-button {
  color: #ffffff !important;
}

.profile-img {
  border-radius: 50%;
  width: 110px;
  margin: 15px;
}

.new-proj-button:hover span {
  color: white;
}

.add-button {
  width: 320px;
  height: 50px;
  border: 1px solid #208bfe !important;
  color: #208bfe;
  border-radius: 25px !important;
}

.add-button:hover,
.add-button:hover > span {
  color: white;
}

.plus-button {
  color: #208bfe;
}

/* Candidate Details */
.profile-container {
  text-align: center;
  padding: 0 10px;
}

.candidate-detail-modal {
  padding: 0px;
}

.candidate-detail-container {
  display: flex;
}

.candidate-detail-container .profile {
  flex-grow: 0;
  flex-shrink: 0;

  border-right-color: #d2d2d2;
  border-right-width: 1px;
  border-right-style: solid;
}

.candidate-detail-container .details {
  flex: 1;
}

.contact-icon-text {
  font-size: 10px;
}

.contact-icon-span-text {
  width: 100%;
  font-size: 12px;
  padding: 0px 10px 0px 5px;
}

.contact-icon-span-text.active {
  cursor: pointer;
  font-family: Montserrat-Medium !important;
}

.request-phone-text {
  cursor: pointer;
}

.web-container {
  padding-left: 30px;
  padding-right: 50px;
}

.web-icon-container {
  display: inline-flex;
}

.web-icon {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

/* Candidate Details - Details Subsection */
.candidate-detail-list-item-title {
  font-family: Montserrat-Bold !important;
  font-size: 16px;
  letter-spacing: -0.81px;
  color: #373737;
  margin-bottom: 2px;
}

.candidate-detail-list-item-details {
  font-family: Montserrat-Medium !important;
  font-size: 12px;
  letter-spacing: -0.61px;
  color: #9b9b9b;
  margin-bottom: 1px;
}

/* SearchKit Overrides */
@import url("../node_modules/searchkit/release/theme.css");

.sk-search-box {
  border-color: #d2d2d2;
  border-width: 1px;
  border-style: solid;
  background: transparent;
  width: 344px;
  height: 25px;
  border-radius: 3px;
}

.sk-search-box.is-focused {
  background: transparent;
}

.sk-search-box .sk-search-box__icon {
  margin: 2px 0 0 9px;
  color: #8d8d8d;
}
.sk-search-box input.sk-search-box__text {
  color: #373737;
  font-size: 12px;
}

.sk-layout__results {
  box-shadow: none;
}

.sk-layout__filters {
  box-shadow: none;
}

.sk-panel__content {
  margin-left: 7%;
  margin-right: 7%;
}

.sk-select:after {
  display: none;
}

.sk-range-slider {
  padding-bottom: 0px;
}

.sk-input-filter form {
  display: flex;
  margin: 0;
  position: relative;
  height: 22px;
  font-size: 12px;
}

.sk-input-filter__remove:before {
  flex: 0 1 20px;
  align-self: flex-end;
  padding: 0px 10px;
  line-height: 22px;
  color: #444;
  content: "x";
  cursor: pointer;
  height: 100%;
}

.sk-search-box__icon:before {
  height: 20px;
  width: 19px;
}

.sk-layout__results .candidate-card .project-body {
  margin: 0px;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.rc-slider-disabled {
  background-color: initial;
}

.rc-slider-step {
  width: 99%;
}

.rc-slider-handle:focus {
  box-shadow: none;
}

.rc-slider-handle:active {
  box-shadow: none;
}

.rc-slider-handle {
  margin-left: -2px;
  border: 2px solid #208bfe !important;
  background-color: #208bfe;
}

.rc-slider-mark-text {
  display: none !important;
}

.rc-slider-dot {
  margin-left: 0px;
  display: none;
}

.rc-slider-rail {
  background-color: #9dcde8;
}

.rc-slider-track {
  background-color: #208bfe !important;
}

.rc-slider-disabled .rc-slider-track {
  background-color: #e9e9e9 !important;
}

.rc-slider-disabled .rc-slider-dot,
.rc-slider-disabled .rc-slider-handle {
  border-color: #ccc !important;
  box-shadow: none;
  background-color: #fff;
  cursor: not-allowed;
}

.rc-slider-handle.rc-slider-handle-1 {
  transform: translateX(10%) !important;
}

.rc-slider-tooltip {
  display: none;
  top: 810px !important;
  padding: 0px !important;
}

.rc-slider-tooltip-arrow {
  display: none;
}

.rc-slider-tooltip-inner {
  padding: 6px 2px;
  min-width: 24px;
  height: 24px;
  font-size: 12px;
  line-height: 1;
  color: #373737;
  text-align: center;
  text-decoration: none;
  background-color: white;
  border-radius: 6px;
  box-shadow: none;
}

/* Bootstrap Overrides */
button {
  outline: none !important;
}

/* bootstrap overwrite */
.popover-body {
  max-height: 200px;
  overflow-y: auto;
  min-width: 255px;
  padding: 20px 15px 15px 20px !important;
}

.navbar-nav .popover-body {
  padding: 0.5rem 0.75rem !important;
  max-height: 225px;
}

textarea:focus {
  outline: none !important;
}

.bs-close-button {
  font-size: 2.25rem !important;
  position: sticky !important;
  font-weight: 200 !important;
}

.btn-outline-primary {
  color: #208bfe !important;
  border-color: #208bfe !important;
}

.btn-outline-primary:hover {
  color: #fff !important;
  background-color: #208bfe !important;
  border-color: #208bfe !important;
}

.btn-outline-primary.disabled,
.btn-outline-primary:disabled {
  color: #007bff !important;
  background-color: transparent !important;
  cursor: initial !important;
}

.btn-outline-primary.focus,
.btn-outline-primary:focus {
  box-shadow: none !important;
}

.form-control {
  border: 1px solid #9b9b9b !important;
  color: black !important ;
}

.form-control:focus {
  box-shadow: none !important;
  outline: none !important;
  border-color: #9b9b9b;
}

input {
  box-shadow: none !important;
  outline: none !important;
  border-color: #9b9b9b;
}

.react-tel-input input.form-control {
  width: 100%;
}

.modal-header {
  border-bottom: initial !important;
  padding: 2rem 1rem !important;
}

.modal-backdrop {
  width: 100% !important;
  height: 100% !important;
}

.candidate-detail-modal .modal-header .modal-title {
  display: none;
}

.candidate-detail-modal .modal-header .close,
.project-detail-modal .modal-header .close,
#create-company-modal .modal-header .close {
  font-size: 2.25rem !important;
  position: sticky !important;
  font-weight: 200 !important;
  padding: initial;
}

.candidate-detail-modal .modal-header .close {
  padding: 0.5rem 1rem;
  margin: initial;
  margin-top: -2rem;
  margin-left: -1rem;
}

.candidate-detail-modal .modal-header {
  padding-bottom: 0.5rem !important;
}

.project-detail-modal .modal-header {
  padding: 0.25rem 1rem !important;
}

.project-detail-modal .modal-header .close {
  float: left;
  margin: 0;
  padding: initial;
}

#company-detail-modal .modal-header {
  padding: 0.25rem 1rem !important;
}

#company-detail-modal .modal-header .close {
  float: left;
  margin: 0;
}

#create-company-modal .modal-header {
  padding: 0.25rem 1rem !important;
}

#create-company-modal .modal-header .close {
  float: left;
  margin: 0;
}

.profile-image-edit-modal h5.modal-title {
  width: 100%;
}

#delete-confirmation-modal .modal-content {
  min-height: 300px;
  min-width: 500px;
}

/* Candidate Info */
.candidate-info-container {
  margin-top: 10px;
  margin-bottom: 10px;
}

.candidate-row .candidate-info-container {
  margin: initial;
}

.candidate-info-container .truncate {
  white-space: initial;
}

/* Candidate Row: large screens and above */
@media only screen and (min-width: 1200px) {
  .candidate-row .contact-icon-container-selected > .contact-icon-image {
    width: 10px;
    height: 12px;
  }

  .candidate-row .contact-icon-row-wrapper {
    min-height: initial;
  }

  /* This will overwrite the ring and use original css rules */
  .candidate-row .contact-icon-container-selected {
    width: 18px;
    height: 18px;
    border-radius: 50%;
    text-align: center;
    vertical-align: middle;
    align-self: center;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #208bfe;
    margin-right: 11px;
  }

  #discover-row-container .initials-container {
    width: 60px !important;
    height: 60px !important;
    font-size: 24px !important;
  }
}

/* Discover + Candidate Row: Small Laptops and above */
@media only screen and (min-width: 992px) {
  #candidate-row-container.candidate-row .contact-icon-container-selected > .contact-icon-image,
  #discover-row-container.candidate-row .contact-icon-container-selected > .contact-icon-image {
    width: 10px;
    height: 12px;
  }

  #candidate-row-container.candidate-row .contact-icon-row-wrapper,
  #discover-row-container.candidate-row .contact-icon-row-wrapper {
    min-height: initial;
  }

  /* This will overwrite the ring and use original css rules */
  #candidate-row-container.candidate-row .contact-icon-container-selected,
  #discover-row-container.candidate-row .contact-icon-container-selected {
    width: 18px;
    height: 18px;
    border-radius: 50%;
    text-align: center;
    vertical-align: middle;
    align-self: center;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #208bfe;
    margin-right: 11px;
  }

  #candidate-row-container.candidate-row .initials-container,
  #discover-row-container.candidate-row .initials-container {
    width: 60px !important;
    height: 60px !important;
    font-size: 24px !important;
  }
}

/* Candidate Row: Small Laptops and below */
@media only screen and (max-width: 991px) {
  .candidate-row .contact-icon-container-selected > .contact-icon-image {
    width: 15px;
    height: 15px;
  }
  .candidate-row .icon-background-container {
    margin: initial;
  }

  .candidate-row .contact-bubble-container {
    width: 35px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .candidate-row .contact-icon-image {
    width: 15px;
    height: 15px;
  }

  .candidate-row .contact-icon-container-selected {
    width: 100%;
    height: 100%;
    margin-right: 16px;
  }

  .candidate-row .mobile-candidate-row-social-container .social-icon-row-wrapper {
    justify-content: space-around;
  }

  #discover-row-container .initials-container,
  #discover-row-container .img-container,
  #discover-row-container .img-container img {
    width: 60px !important;
    height: 60px !important;
    font-size: 24px !important;
  }
}

/* Candidate Initials Override */
.candidate-list-item-container div.initials-container {
  /* TODO: make Capital Initials component take a style object to pass in */
  width: 75px !important;
  height: 75px !important;
  font-size: 36px !important;
}

.project-body.project-detail-modal-body > div.initials-container {
  width: 110px !important;
  height: 110px !important;
}

#company-row .experience-skills-container .mobile-experience-container div.initials-container,
#project-row .experience-skills-container .mobile-experience-container div.initials-container {
  width: 25px !important;
  height: 25px !important;
  font-size: 13px !important;
}

#company-row .experience-skills-container .candidate-detail-section-header-container,
#project-row .experience-skills-container .candidate-detail-section-header-container {
  padding-right: 16px;
}

.recent-candidate-container .initials-container {
  width: 47px !important;
  height: 47px !important;
  font-size: 18px !important;
}

#company-row .initials-container,
#project-row .initials-container {
  width: 60px !important;
  height: 60px !important;
  font-size: 24px !important;
}

/* Project Details */

/* Project Details--Remove Candidate Section */
.transform {
  -webkit-transition: all 2s ease;
  -moz-transition: all 2s ease;
  -o-transition: all 2s ease;
  -ms-transition: all 2s ease;
  transition: all 2s ease;
}

.slide-out-left {
  -webkit-animation: slide-out-left 0.3s ease-out forwards;
  animation: slide-out-left 0.3s ease-out forwards;
  display: inline;
}

@-webkit-keyframes slide-out-left {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(-20px);
    transform: translateX(-20px);
    opacity: 0;
  }
}
@keyframes slide-out-left {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(-20px);
    transform: translateX(-20px);
    opacity: 0;
  }
}

.slide-down {
  display: flex;
  height: 0px;
  overflow: hidden;
  visibility: hidden;
  -webkit-animation: slide-down 0.5s ease-in 0.5s forwards 1;
  animation: slide-down 0.5s ease-in 0.5s forwards 1;
  margin-bottom: 22.5px;
}

@-webkit-keyframes slide-down {
  0% {
    height: 0px;
    visibility: hidden;
  }
  100% {
    height: 40px;
    visibility: visible;
  }
}
@keyframes slide-down {
  0% {
    height: 0px;
    visibility: hidden;
    opacity: 1;
  }

  100% {
    height: 40px;
    visibility: visible;
    opacity: 1;
  }
}

.slide-up {
  display: flex;
  height: 40px;
  overflow: hidden;
  visibility: visible;
  -webkit-animation: slide-up 0.5s ease-in 0.5s forwards 1;
  animation: slide-up 0.5s ease-in 0.5s forwards 1;
}

@-webkit-keyframes slide-up {
  0% {
    height: 40px;
    visibility: visible;
  }
  100% {
    height: 0px;
    visibility: hidden;
  }
}
@keyframes slide-up {
  0% {
    height: 40px;
    visibility: visible;
  }
  100% {
    height: 0px;
    visibility: hidden;
  }
}

.slide-down-and-up {
  display: none;
  height: 0px;
  overflow: hidden;
  visibility: visible;
  -webkit-animation: slide-down-and-up 2s ease-out forwards;
  animation: slide-down-and-up 2s ease-out forwards;
  /* display: none; */
}

@-webkit-keyframes slide-down-and-up {
  0% {
    height: 0px;
    visibility: hidden;
  }
  33% {
    height: 40px;
    visibility: visible;
  }
  66% {
    height: 40px;
    visibility: visible;
  }
  100% {
    height: 0px;
    visibility: hidden;
  }
}

@keyframes slide-down-and-up {
  0% {
    height: 0px;
    visibility: hidden;
  }
  33% {
    height: 40px;
    visibility: visible;
  }
  66% {
    height: 40px;
    visibility: visible;
  }
  100% {
    height: 0px;
    visibility: hidden;
  }
}

.fade-in {
  -webkit-animation: fade-in 0.3s ease-out forwards;
  animation: fade-in 0.3s ease-out forwards;
  display: inline;
}

.fade-in-no-translate {
  -webkit-animation: fade-in-no-translate 0.3s ease-out forwards;
  animation: fade-in-no-translate 0.3s ease-out forwards;
  display: inline;
}

.faded {
  display: none;
}

@-webkit-keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.bold-fade-text {
  -webkit-animation: bold-fade-text 5s ease-out forwards;
  animation: bold-fade-text 5s ease-out forwards;
}

@-webkit-keyframes bold-fade-text {
  0% {
    font-family: Montserrat-Bold !important;
    color: #000000;
  }
  100% {
    font-weight: bold;
    color: #6c6c6c;
  }
}
@keyframes bold-fade-text {
  0% {
    font-family: Montserrat-Bold !important;
    color: #000000;
  }
  100% {
    font-weight: bold;
    color: #6c6c6c;
  }
}

/* Small laptop screens and below  */
@media only screen and (max-width: 1199.98px) {
  @-webkit-keyframes fade-in {
    0% {
      opacity: 0;
      -webkit-transform: translateX(0);
      transform: translateX(0);
    }
    100% {
      opacity: 1;
      -webkit-transform: translateX(-50px);
      transform: translateX(-50px);
    }
  }
  @keyframes fade-in {
    0% {
      opacity: 0;
      -webkit-transform: translateX(0);
      transform: translateX(0);
    }
    100% {
      opacity: 1;
      -webkit-transform: translateX(-50px);
      transform: translateX(-50px);
    }
  }
}

@-webkit-keyframes fade-in-no-translate {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fade-in-no-translate {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/* Generics */
.bold-header-text {
  font-family: Montserrat-Bold !important;
  font-size: 14px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: -0.71px;
  color: #373737;
}

.truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 340px;
}

.row-action-button-text:hover {
  color: #208bfe;
  cursor: pointer;
}

/* Specific Media Queries */

/* Small Desktops and Below */
@media only screen and (max-width: 1199.98px) {
  .candidate-row .mobile-candidate-row-social-container .social-contact-header-text,
  .candidate-row .mobile-candidate-row-contact-container .social-contact-header-text {
    margin-bottom: 9px;
  }
}

/* Tables (iPad) and Below */
@media only screen and (max-width: 991.98px) {
  .rc-slider-tooltip {
    top: 844px !important;
    padding: 0px !important;
  }

  .candidate-list-container {
    margin-top: 15px;
  }
  .candidate-list-item-container {
    padding: 15px;
  }
  .sk-search-box {
    width: 90%;
    margin: auto;
  }
  .candidate-row .initials-container {
    width: 100px !important;
    height: 100px !important;
    font-size: 36px !important;
  }
  #company-row .initials-container,
  #project-row .initials-container {
    width: 100px !important;
    height: 100px !important;
    font-size: 36px !important;
  }

  #company-row .profile-image-container .img-container,
  #company-row .profile-image-container .img-container img,
  #project-row .profile-image-container .img-container,
  #project-row .profile-image-container .img-container img {
    width: 100px !important;
    height: 100px !important;
    font-size: 36px !important;
  }

  #discover-row-container .initials-container,
  #discover-row-container .img-container,
  #discover-row-container .img-container img,
  #candidate-row-container .initials-container,
  #candidate-row-container .img-container,
  #candidate-row-container .img-container img {
    width: 100px !important;
    height: 100px !important;
    font-size: 36px !important;
  }
}

/* Small Devices and Below */
@media only screen and (max-width: 575.98px) {
  .modal-dialog {
    margin: 0 !important;
  }

  .model-content {
    border: none;
  }

  .candidate-detail-modal.modal-dialog {
    height: 100%;
    align-items: initial;
  }

  .project-detail-modal.modal-dialog,
  .project-detail-modal .modal-content,
  #company-detail-modal .modal-dialog,
  #company-detail-modal .modal-content,
  #delete-confirmation-modal.modal-dialog {
    align-items: initial;
    height: 100%;
    max-height: initial;
  }

  .profile-image-edit-modal,
  .profile-image-edit-modal .modal-content,
  #create-company-modal,
  #create-company-modal .modal-content,
  #create-project-modal,
  #create-project-modal .modal-content,
  #delete-confirmation-modal .modal-content {
    height: 100%;
  }

  #delete-confirmation-modal .modal-content {
    min-width: initial;
  }

  #candidate-row-container .candidate-row-wrapper .initials-container {
    width: 100px !important;
    height: 100px !important;
    font-size: 36px !important;
  }
}

/* Medium Size Screens and Above */
@media only screen and (min-width: 768px) {
  .paperclip-icon-container {
    display: none !important;
  }
}
