.table {
  width: 100%;
  table-layout: fixed;
}

.table td {
  overflow: hidden;
  text-overflow: ellipsis;
}

.table th {
  padding-top: 12px;
  padding-bottom: 12px;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #868686;
  white-space: nowrap;
  position: sticky;
  top: 0;
  background: #ffffff;
  z-index: 1;
}

.table th:before {
  content: '';
  border-bottom: 1px solid #D6D6D6;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.tr {
  color: #1D1D1D;
}

.tr:hover td {
  background-color: rgba(0,0,0,0.03);
}

.tr td {
  padding-top: 14px;
  padding-bottom: 14px;
  font-weight: 400;
  font-size: 14px;
  border-top: 1px solid #D6D6D6;
  transition: all 0.15s ease-in;
}

.tr:first-child td {
  border-color: transparent;
}

.table tbody tr:last-child td {
  border-bottom: 0;
}

.tdName {
  /* padding-left: 24px; */
  width: 400px;
}

.tdOrder {
  width: 80px;
  text-align: center;
}

.trDragging {
  border-top: 0;
  border-bottom: 0;
}

.dragHandle {
  padding: 6px 3px;
  border-radius: 4px;
  cursor: grab;
  margin-right: 8px;
}

.dragHandle svg {
  width: 16px;
  height: auto;
}

.dragHandle svg path {
  fill: var(--c-blue)
}

.deleteButton {
  padding: 6px 6px 6px 3px;
}

.tdActions {
  width: 80px;
}

.tdActions :global .icon-pencil svg {
  width: 16px;
  height: auto;
}

.tdActions :global .icon-pencil svg path {
  fill: var(--c-blue);
}

.tdActions :global .icon-fa-trash svg {
  width: 12px;
  height: auto;
}

.tdActions :global .icon-fa-trash path {
  fill: var(--c-red);
}

.table.short td {
  padding-top: 4px;
  padding-bottom: 4px;
  border: 0;
}

.table.short .tdActions {
  font-size: 0;
  width: 40px;
  text-align: right;
}

.table.short .tdOrder {
  width: 40px;
  text-align: left;
}

.table.short .tdName {
  width: unset;
}