.holder {
  padding-top: 18px;
}

.controls {
  padding: 18px 0 0 0;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 24px;
}

.controls > * {
  min-width: 150px;
}