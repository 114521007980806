.content {
  color: #b4b0b0;
  padding-bottom: 40px;
  text-align: center;
  max-width: 347px;
  margin: 0 auto;
}

.content b {
  font-weight: var(--fw-medium);
  color: var(--c-black)
}