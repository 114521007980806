.__input {
  width: 100%;
  font-size: 14px;
  font-family: "Montserrat", serif;
  color: #373737;
}

.__input--error {
  color: red;
  font-size: 10px;
}

.__input--error-hidden {
  display: none;
}

.__input--field {
  width: 100%;
  height: 34px;
  border-radius: 3px;
  border: solid 1px #9b9b9b;
  padding: 0 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  outline: none;
}

.__input--field-error {
  width: 100%;
  height: 34px;
  border-radius: 3px;
  border: solid 1px red;
  padding: 0 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.__input--field::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #9b9b9b;
  opacity: 1; /* Firefox */
}

.__input--field:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #9b9b9b;
}

.__input--field::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #9b9b9b;
}
