.field {
  display: flex;
  align-items: baseline;
}

.field > *:last-child {
  flex: 1;
}

.label {
  width: 110px;
  flex-shrink: 0;
}