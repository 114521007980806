.inputHolder {
  display: flex;
  align-items: center;
}

.actions {
  display: flex;
  align-items: center;
  margin-left: 12px;
}

.actions > * {
  margin-left: 4px;
}

.actions > *:first-child {
  margin-left: 0;
}

.input {
  width: auto;
  height: 24px;
  font-size: 12px;
}

.buttonEdit {
  margin-left: 8px;
  position: relative;
  top: 1px;
}

.buttonSave svg path {
  fill: var(--c-blue);
}

.buttonCancel svg path {
  fill: var(--c-gray);
}